import React, {useState} from 'react'

function Contact() {
  const [name, setName]= useState("");
  const [email, setEmail]= useState("");
  const [subject, setSubject]=useState("");
  const [message, setMessage] = useState("");

  // const handleChange = (event, setValue) => {
  //   setValue(event.target.value);
  // };
  
  const handleSubmit = (event)=>{
    event.preventDefault()

    const data= {
      name, email, subject, message
    }
    console.log(data);
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");

  }

  return (
    <section id="contact" className="py-5 bg-light">
    <div className="container">
      <h2 className="text-center mb-4">Contact Me</h2>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" placeholder="Your Name" value={name}
          onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Your Email" value={email}
          onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">Subject</label>
              <input type="text" className="form-control" id="subject" placeholder="Subject" value={subject}
          onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows="5" placeholder="Your Message" value={message}
          onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <button type="button" onClick={handleSubmit} className="btn btn-primary">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Contact