import React from 'react';
import { Link } from 'react-router-dom';

import Mail from '../images/mail.png';
import LinkedIn from '../images/linkedin.png';
import GitHub from '../images/github.png';
import X from '../images/x.png';

function Footer() {
  return (
    <footer className="bg-light py-4 mt-auto">
      <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
        <p className="mb-2 mb-md-0">&copy; 2024 Ifeanyi Ukiwe. All rights reserved.</p>
        <div className="d-flex">
          <a href="https://www.linkedin.com/in/ifeanyiukiwe" target="_blank" rel="noopener noreferrer" className="mx-2">
            <img src={LinkedIn} alt="" width={30} />
          </a>
          <a href="https://github.com/ifeanyiukiwe" target="_blank" rel="noopener noreferrer" className="mx-2">
          <img src={GitHub} alt="" width={30} />
          </a>
          <a href="mailto:contact@ifeanyiukiwe.com" target='_blank' className="mx-2">
          <img src={Mail} alt="Mail" width={30} />
          </a>
          <a href="https://x.com/duio1541" target='_blank' className="mx-2">
          <img src={X} alt="" width={30} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
