import React from 'react';
import { Link } from 'react-router-dom';

import Placeholder from "../images/placeholder.jpg"

function About() {
  return (
    <section id="about" className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">About Me</h2>
        <div className="row align-items-center">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img src={Placeholder} className="img-fluid rounded-circle" alt="About Me" />
          </div>
          <div className="col-lg-8">
            <p>My name is Ifeanyi Ukiwe. I am a passionate Web Developer focused on building dynamic and responsive user interfaces using various tools like HTML, CSS, JavaScript, and React. With a strong attention to detail and a commitment to creating seamless user experiences, I strive to deliver high-quality web applications that meet and exceed client expectations.</p>
            <p>When I'm not coding, I enjoy exploring new technologies, reading tech blogs, and contributing to open-source projects. Feel free to reach out if you'd like to collaborate on a project or just chat about web development!</p>
            <Link to="https://drive.google.com/file/d/1H-axxRv2B-rI3Y2Dpio0v6V7nZx2j42m/view?usp=sharing" className="btn btn-primary mt-4" target="_blank" rel="noopener noreferrer">View Resume</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
