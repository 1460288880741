import React from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from "../images/logo.jpg";

const navs = [ 
  {
  title: "Home",
  url: '/'
  },
  {
    title: "About",
    url: '/about'
  },
  {
    title: "Skills",
    url: '/skills'
  },
  {
    title: "Projects",
    url: '/projects'
  },
  {
    title: "Contact",
    url: '/contact'
  }
]

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container">
    <Link className="navbar-brand h1 fw-bold" to="/">
      {/* <img src={Logo} alt='Logo' width={30} className='me-2' /> */}
      <span>Ifeanyi U.</span>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          {navs.map((nav, index) => (
            <li key={index} className="nav-item">
            <Link className="nav-link active" aria-current="page" to={nav.url}>{nav.title}</Link>
          </li>
          ))}
        </ul>
      </div>
    </div>
    </nav>
  )
}

export default Navbar